import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// A custom theme for this app
const darkTheme = createTheme({
	palette: {
		background: {
			default: '#102027',
			paper: 'rgba(28, 43, 50, 1)',
		},
		primary: {
			main: 'rgb(187,134,252)',
			contrastText: '#ffffff',
		},
		text: {
			primary: "#fff",
			secondary: "rgba(255, 255, 255, 0.7)",
			disabled: "rgba(255, 255, 255, 0.5)",
		},
	},
	typography: {
		body1: {
			fontSize: "1.1875rem"
		},
	},
	components: {
		MuiAppBar: {
			defaultProps: {
				color: 'transparent',
			},
		}
	},

});

// @ts-ignore
// const lightTheme = createTheme({
// 	palette: {
// 		background: {
// 			default: '#fafafa',
// 			// paper: 'rgba(0, 0, 0, 0.05)',
// 		},
// 		primary: {
// 			main: '#3f51b5',
// 			contrastText: '#ffffff',
// 		},
// 		text: {
// 			primary: "rgba(0, 0, 0, 0.87)",
// 			secondary: "rgba(0, 0, 0, 0.54)",
// 			disabled: "rgba(0, 0, 0, 0.38)",
// 		},
// 	},
// 	typography: {
// 		body1: {
// 			fontSize: "1.125rem"
// 		}
// 	},
// 	components: {
// 		MuiAppBar: {
// 			defaultProps: {
// 				color: 'transparent',
// 			},
// 		}
// 	},
// });

// export default responsiveFontSizes(darkTheme);
export default responsiveFontSizes(
	darkTheme,
	{
		variants: [
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'h6',
			'subtitle1',
			'subtitle2',
			'caption',
			'button',
			'overline'
		]
	}
);

